<template>
  <ValidationObserver @submit="addSyllabusTemplate">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalHeading }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="syllabusTemplateData.syllabus_provider_id"
            label="title"
            title="COURSE_PRO"
            :options="courseProviderList"
            reduce="id"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <InputBox
            v-model="syllabusTemplateData.subject_title"
            type="text"
            name="SUB_TITLE"
            title="SUB_TITLE"
            label="SUB_TITLE"
            placeholder="SUB_TITLE"
            class="flex-1"
            rules="required"
          />
          <InputBox
            v-model="syllabusTemplateData.title"
            type="text"
            name="TITLE"
            title="TITLE"
            label="TITLE"
            placeholder="TITLE"
            class="flex-1"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <InputBox
            v-model="syllabusTemplateData.grade"
            type="text"
            name="GRADE"
            title="GRADE"
            label="GRADE"
            placeholder="GRADE"
            class="flex-1"
            rules="required"
          />
          <InputBox
            v-model="syllabusTemplateData.edition"
            type="text"
            name="YEAR/EDITION"
            title="YEAR/EDITION"
            label="YEAR/EDITION"
            placeholder="YEAR/EDITION"
            class="flex-1"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-if="!currentCampusScope"
            v-model="syllabusTemplateData.campus_id"
            title="Campuses"
            label="title"
            :options="campuses"
            class="flex-1"
            reduce="id"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              {{ saveBtnTitle }}
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    Loader,
    ValidationObserver,
    UiSingleSelect,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      syllabusTemplateData: {
        title: '',
        edition: '',
        subject_title: '',
        syllabus_provider_id: '',
        grade: '',
        campus_id: '',
      },
      courseProviderList: [],
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
      isLoading: (state) => state?.layout?.isLoading,
      campuses: (state) => state?.layout?.campuses,
    }),
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    modalHeading() {
      return this.edit ? 'Edit Syllabus Template' : 'Add Syllabus Template'
    },
  },

  created() {
    if (this.edit) this.setSyllabusTemplateData()
  },
  mounted() {
    this.fetchCourseProvider()
  },
  methods: {
    fetchCourseProvider() {
      this.getCourseProvidersList().then((res) => {
        this.courseProviderList = res?.records
      })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async addSyllabusTemplate() {
      try {
        this.$store.commit('layout/IS_LOADING', true)
        let data = {
          course: this.syllabusTemplateData,
        }
        if (this.edit) data.id = this.edit.id

        await this.createAndUpdateSyllabusTemplate(data)
        this.$store.commit('layout/IS_LOADING', false)
        this.$emit('toggle', true)
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    setSyllabusTemplateData() {
      this.syllabusTemplateData.title = this.edit?.title || ''
      this.syllabusTemplateData.subject_title = this.edit?.subject_title || ''
      this.syllabusTemplateData.edition = this.edit?.edition || ''
      this.syllabusTemplateData.grade = this.edit?.grade || ''
      this.syllabusTemplateData.campus_id = this.edit?.campus_id || ''
      this.syllabusTemplateData.syllabus_provider_id = this.edit?.syllabus_provider?.id
    },
    ...mapActions('syllabusmanager', ['createAndUpdateSyllabusTemplate', 'getCourseProvidersList']),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>
