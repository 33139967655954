<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <UiModalContainer
      :modal-show="modal"
      :modal-width="50"
      footer-classes="flex justify-end gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>{{ modalHeading }}</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="classId"
            title="CLASS"
            label="title"
            :options="classList"
            class="flex-1"
            reduce="id"
            rules="required"
          />
          <UiSingleSelect
            v-model="sectionId"
            title="Section"
            label="title"
            :options="sectionsList"
            class="flex-1"
            reduce="id"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="subjectId"
            title="SUBJECT"
            :options="subjectList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @search="getSubjects"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="w-full flex justify-between">
          <div>
            <UIButton
              button="primary"
              :disabled="isLoading"
              type="button"
              class="px-3"
              @click="handleSubmit(redirectToCustomizeImportPage)"
            >
              Custom Import
            </UIButton>
          </div>
          <div class="flex items-center gap-5">
            <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
            <UIButton
              button="primary"
              type="button"
              :disabled="isLoading"
              @click="handleSubmit(importTemplateHandler)"
            >
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>Import</span>
            </UIButton>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    UIButton,
    UiSingleSelect,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    currentTemplate: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      classList: [],
      sectionsList: [],
      subjectList: [],
      classId: null,
      sectionId: '',
      subjectId: '',
    }
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
    }),
    modalHeading() {
      return `Import ${this.currentTemplate.title}`
    },
  },
  watch: {
    classId: {
      handler(id) {
        this.sectionId = ''
        this.sectionsList = []
        this.subjectId = ''
        this.subjectList = []
        this.setSection(id)
        if (id) this.getSubjects()
      },
    },
    sectionId: {
      handler() {
        this.subjectId = ''
        this.subjectList = []
        this.getSubjects()
      },
    },
  },
  mounted() {
    this.getCampusClasses()
  },
  methods: {
    getSubjects(query) {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', this.classId),
        },
      }

      if (this.sectionId)
        payload.$where = {
          ...payload.$where,
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.sectionId),
        }

      this.classSubjects(payload).then((res) => {
        this.subjectList = res?.data?.records || []
      })
    },
    getCampusClasses() {
      this.getClassesList().then((res) => {
        this.classList = res.records
      })
    },
    setSection(id) {
      this.classList.forEach((classItem) => {
        if (classItem.id === id) this.sectionsList = classItem.sections
      })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('toggle')
          break
        case 'close':
          this.$emit('toggle')
          break
      }
    },

    redirectToCustomizeImportPage() {
      this.$router?.push({
        name: 'subject-syllabus-template-import',
        params: { id: this.currentTemplate.id },
        query: { classId: this.classId, sectionId: this.sectionId, subjectId: this.subjectId },
      })
    },
    async importTemplateHandler() {
      let payload = {
        id: this.currentTemplate.id,
        section_id: this.sectionId,
        class_id: this.classId,
        subject_id: this.subjectId,
      }
      this.importSyllabusTemplateToSection(payload).then((response) => {
        this.$store.commit(
          'toast/NEW',
          {
            type: 'success',
            message: response?.message || 'Import started',
          },
          { root: true },
        )
        this.$emit('toggle')
      })
    },
    ...mapActions('classes', ['getClassesList']),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('syllabusmanager', ['importSyllabusTemplateToSection']),
  },
}
</script>
