<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="SYLLABUS_TEMP"
      tooltip-title="SYLLABUS_TEMP"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapperVue layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.grade"
            class="flex-1"
            title="Grade"
            type="text"
            placeholder="Grade"
            :filter="{
              option: FILTER_KEYS.ILIKE,
              key: 'grade',
            }"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.edition"
            class="flex-1"
            title="Edition"
            type="text"
            placeholder="Edition"
            :filter="{
              option: FILTER_KEYS.ILIKE,
              key: 'edition',
            }"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model="filtersPayload.syllabus_provider_id"
            label="title"
            reduce="id"
            title="COURSE_PROVIDER"
            :options="courseProviderList"
            class="flex-1"
            :filter="{
              option: FILTER_KEYS.EQUAL,
              key: 'syllabus_provider_id',
            }"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-if="!campus"
            v-model.trim="filtersPayload.campus_id"
            title="CAMPUSES"
            label="title"
            :options="campuses"
            class="flex-1"
            reduce="id"
            :filter="FILTERS_LIST.CAMPUS"
            @emitQuery="updateQuery"
          />
        </InputFieldWrapperVue>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import InputFieldWrapperVue from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiInput,
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapperVue,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTER_KEYS,
      FILTERS_LIST,
      $where: {},
      queries: {},
      appliedQueries: {},
      filtersCount: 0,
      courseProviderList: [],
      title: 'title',
      filtersPayload: {
        title: '',
        grade: '',
        edition: '',
        syllabus_provider_id: '',
        campus_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState('layout', ['campuses']),
    ...mapState({
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    campus: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.fetchCourseProvider()
  },
  methods: {
    fetchCourseProvider() {
      this.isLoading = true
      this.getCourseProvidersList().then((res) => {
        this.courseProviderList = res?.records
      })
    },
    getFiltersCount() {
      this.fetchCourseProvider()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },

    ...mapActions('syllabusmanager', ['getCourseProvidersList']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
