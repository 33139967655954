<template>
  <div>
    <SyllabusTemplateFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(subjectTemplateList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, requiredTableHeaders)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH v-i18n="dashboard">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(template, index) in subjectTemplateList" :key="`index-${index}`">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            <span
              class="capitalize cursor-pointer text-primary-purple-600"
              @click="redirectToDetailpage(template.id)"
            >
              {{ template.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Subject Title', ['Subject Title'])">
            <span class="capitalize">
              {{ template.subject_title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Couse Provider', ['Couse Provider'])">
            <span class="capitalize">
              {{
                (template.syllabus_provider && template.syllabus_provider.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Grade')">
            <span class="capitalize">
              {{ template.grade || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Year/Edition')">
            <span class="capitalize">
              {{ template.edition || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])">
            <span class="capitalize">
              <BadgeDisplay
                :options="template.campus"
                :default-falsy-title="GENERAL_CONSTANTS.FOR_ALL_CAMPUSES"
                :profile="true"
                label="title"
                :is-object-concated="false"
              />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :idx="index"
              :current-user="template"
              :action-list="template.actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(syllabusManagerCount, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecord($event)"
    />
    <SyllabusTemplateModal
      v-if="isSyllabusTemplateVisible"
      :modal="isSyllabusTemplateVisible"
      :edit="currentSelectedTemplate"
      @toggle="toggleSyllabusTemplateModal"
    />
    <UIConfirmationModal
      v-if="confirmation"
      :modal="confirmation"
      heading="DELETE_SYLL_TEMP"
      message="Are you sure you want to delete "
      button-text="Delete"
      :is-loading="isDeleteModalLoading"
      :name="currentSelectedTemplate.title"
      remaining-message="Template ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteTemplate"
    />

    <ImportConfirmationModal
      v-if="isImportConfirmationVisible"
      :modal="isImportConfirmationVisible"
      :current-template="currentSelectedTemplate"
      @toggle="toggleImportConfirmationModal"
    />
  </div>
</template>

<script>
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import TableAction from '@components/TableAction.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil.js'
import fileMixins from '@/src/mixins/file-mixins'
import SyllabusTemplateModal from '@views/admin/modals/AddAndEditSyllabusTemplateModal.vue'
import SyllabusTemplateFilter from '@src/router/views/syllabus/template/SyllabusTemplateFilter.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import ImportConfirmationModal from '@views/syllabus/template/modals/ImportConfirmationModal.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { mapState, mapActions } from 'vuex'
import SYLLABUS_TEMPLATE from '@views/syllabus/template/syllabusTemplte.json'
import { templateStats } from '@views/syllabus/template/syllabus-template.ulit.js'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TableAction,
    SyllabusTemplateModal,
    Loader,
    SyllabusTemplateFilter,
    NoRecordFound,
    Pagination,
    UIConfirmationModal,
    ImportConfirmationModal,
    BadgeDisplay,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [scrollMixin, fileMixins],
  data() {
    return {
      dashboard: 'dashboard',
      GENERAL_CONSTANTS,
      isEmpty,
      defaultSelectedColumns: SYLLABUS_TEMPLATE.templatesDefaultSelectedColumns,
      tableHeaders: SYLLABUS_TEMPLATE.templatesTableHeaders,
      requiredTableHeaders: SYLLABUS_TEMPLATE.requiredTableHeaders,
      filtersData: {},
      isSyllabusTemplateVisible: false,
      showPagination: false,
      isLoading: false,
      currentSelectedTemplate: null,
      subjectTemplateList: [],
      confirmation: false,
      syllabusManagerCount: 0,
      isImportConfirmationVisible: false,
      isDeleteModalLoading: false,
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
      tabRightBar: (state) => state.layout.tabRightNav,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.setRightbarData()
        this.filterRecord()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.setRightbarData(this.syllabusManagerCount)
      },
    },
  },

  created() {
    this.setRightbarData()
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    paginationCounts,
    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'TEMP',
          buttons: [
            {
              title: 'ADD_N_TEMP',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleSyllabusTemplateModal()
                },
              },
            },
          ],
        },
        stats: templateStats(count, this.currentCampusScope),
      }
      this.setRightbarContent(rightBarContent)
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },

    typeAction(action, currentTemplate, idx) {
      this.currentSelectedTemplate = currentTemplate
      switch (action) {
        case 'EDIT':
          this.toggleSyllabusTemplateModal()
          break
        case 'Delete':
          this.toggleConfirmationModal()
          break
        case 'IMPORT':
          this.toggleImportConfirmationModal()
          break
      }
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentSelectedTemplate = null
    },

    toggleImportConfirmationModal() {
      this.isImportConfirmationVisible = !this.isImportConfirmationVisible
      if (!this.isImportConfirmationVisible) this.currentSelectedTemplate = null
    },
    filterRecord(range) {
      this.isLoading = true

      let paginationRange = this.getPaginationRange(range)

      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getSubjectSyllabusTemplateList(payload)
        .then((respose) => {
          this.subjectTemplateList = respose.records
          this.subjectTemplateList.forEach((template) => {
            template.actionList = this.tableActionList(!!template.campus)
          })
          this.syllabusManagerCount = respose.meta.total_records
          this.showPagination = this.syllabusManagerCount > GENERAL_CONSTANTS.RECORD_LIMIT
          this.setRightbarData(this.syllabusManagerCount)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    tableActionList(isCampusesAvailable) {
      let actionList = [
        { name: 'IMPORT', basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL] },
        { name: 'EDIT', basedOn: [SCOPE_LEVELS.ROOT_LEVEL] },
        { name: 'Delete' },
      ]
      if (isCampusesAvailable)
        actionList.unshift({ name: 'EDIT', basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL] })
      return actionList
    },

    getPaginationRange(range) {
      return {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
    },

    toggleSyllabusTemplateModal(payload) {
      this.isSyllabusTemplateVisible = !this.isSyllabusTemplateVisible
      if (!this.isSyllabusTemplateVisible) this.currentSelectedTemplate = null
      if (payload) this.filterRecord()
    },
    deleteTemplate() {
      this.isDeleteModalLoading = true
      let id = this.currentSelectedTemplate?.id
      this.deleteSyllabusTemplate(id)
        .then(() => {
          this.toggleConfirmationModal()
          this.filterRecord()
        })
        .finally(() => {
          this.isDeleteModalLoading = false
        })
    },

    redirectToDetailpage(templateId) {
      this.$router?.push({
        name: 'subject-syllabus-template-detail-view',
        params: { id: templateId },
      })
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('syllabusmanager', ['getSubjectSyllabusTemplateList', 'deleteSyllabusTemplate']),
  },
}
</script>
